import { Center, Stack, Text } from "@chakra-ui/react";

import PageContainer from "../components/common/PageContainer/PageContainer";
import CoffeeWithFriend from "../assets/images/coffee_with_friends.svg";
import Image from "next/image";
import Button from "../components/common/Button/Button";
import Link from "next/link";

const NotFoundPage = () => {
  return (
    <PageContainer>
      <Center flexDirection={"column"} gap={8}>
        <Text> ページが見つかりません </Text>
        <Image
          src={CoffeeWithFriend}
          width={"300px"}
          height={"300px"}
          alt={"ページが見つかりません"}
        />
        <Link href="/">
          <a>
            <Button mode="primary" w={"300px"}>
              Askqでアンケートをつくる
            </Button>
          </a>
        </Link>
      </Center>
    </PageContainer>
  );
};

export default NotFoundPage;
